// Here you can add other styles
.c-wrapper {
  background: white;
}

.clear-both {
  clear: both;
}

.float-left {
  float: left;
}

.width400 {
  width: 400px;
}

.ml-10 {
  margin-left: 10px;
}

.width200 {
  width: 200px;
}

.margin_tb10{
  margin-top: 10px;
  margin-bottom: 10px;
}